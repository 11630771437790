<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    الزيارات
                </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                المسوق
                            </th>
                            <th>المدرسة</th>
                            <th>تاريخ الزيارة</th>
                            <th>مهتمة</th>
                            <th>
                                المبلغ المحصل
                            </th>
                            <th>
                                سجل النشاط
                            </th>
                            <th>خيارات</th>
                        </thead>
                        <tbody>
                            <tr v-for="u in visits" :key="u._id">
                                <td>
                                    {{ u.name }}
                                </td>
                                <td>
                                    {{ u.schoolname }}
                                </td>
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    <span v-if="u.interested == 0" class="btn btn-sm btn-warning">
                                        لم يتم التعيين
                                    </span>
                                    <span v-if="u.interested == 1" class="btn btn-sm btn-success">
                                        <i class="fa fa-check"></i>
                                        مهتمة
                                    </span>
                                    <span v-if="u.interested == 2" class="btn btn-sm btn-danger">
                                        <i class="fa fa-times"></i>
                                        غير مهتمة
                                    </span>
                                </td>
                                <td>
                                    <span v-if="u.money == 0" class="btn btn-sm">
                                        لم يتم التحصيل
                                    </span>
                                    <span v-if="u.money > 0" class="btn btn-sm btn-outline-success">
                                        {{ u.money }}
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" v-b-modal.logs @click="logs = u.logs; current = u">
                                        <i class="fa fa-list"></i>
                                        عرض ({{ u.logs.length }})
                                    </button>
                                </td>
                                <td>
                                    <b-dropdown size="sm" text="خيارات">
                                        <b-dropdown-item @click="current = u" v-b-modal.edit>
                                            <i class="fa fa-list"></i>
                                            التفاصيل
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="edit" title="تفاصيل الزيارة" hide-footer>
        <div class="form-group">
          <label for="">اسم المدرسة</label>
          <input type="text"
            class="form-control" v-model="current.schoolname" readonly>
        </div>
        <div class="form-group">
          <label for="">جوال المدرسة</label>
          <input type="text"
            class="form-control" v-model="current.phone" readonly>
        </div>
        <div class="form-group">
          <label for="">التفاصيل</label>
          <textarea class="form-control" v-model="current.details" readonly rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">موعد الزيارة</label>
          <input type="date"
            class="form-control" v-model="current.date" readonly>
        </div>
    </b-modal>
    <b-modal id="logs" title="سجل النشاط" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        التفاصيل
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in logs" :key="i">
                        <td>
                            {{ l.date }}
                        </td>
                        <td>
                            {{ l.content }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            visits: [],
            create: {money: 0, date: new Date().toISOString().split("T")[0], interested: 0},
            logs: [],
            details: "",
            current: {},
            logg: ""
        }
    },
    created(){
        this.getR()
    },
    methods: {
        getR(){
            var g = this;
            $.post(api + '/admin/resellers/visits/list', {
                jwt: localStorage.getItem('jwt')
            }).then(function(r){
                g.visits = r.response;
            })
        }
    }
}
</script>

<style>

</style>